<template>
    <div>     
        <SettingsForm ref="form"
            :tKey="tKey"
            :base="null"
            :form="form"
            :formAttributes="formAttributes"
            :validation="validations"
        >
            <b-button variant="success" @click="save">
                <font-awesome-icon class="mr-2" icon="save" />
                <span v-html="$t(tKey + 'save')" />
            </b-button>
        </SettingsForm>
    </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import SettingsForm from '@/components/settings/SettingsForm'
export default {
    name: 'SecuritySettings',
    components: {
        SettingsForm,
    },
    data() {
        return {
            tKey: 'settings.Security.',
            form: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
        }
    },
    computed: {
        formAttributes() {
            return [
                {
                    key: 'oldPassword',
                    type: 'password',
                    validation: ['required',],
                },
                {
                    key: 'newPassword',
                    type: 'password',
                    validation: ['required', 'strong',],
                },
                {
                    key: 'confirmPassword',
                    type: 'password',
                    validation: ['required', 'sameAs',],
                },
            ]
        },
        validations() {
            return {
                form: {
                    oldPassword: {
                        required,
                    },
                    newPassword: {
                        required,
                        minLength: minLength(6),
                        strong: (value) => {
                            const containsUppercase = /[A-Z]/.test(value)
                            const containsLowercase = /[a-z]/.test(value)
                            const containsNumber = /[0-9]/.test(value)
                            const containsSpecial = /[#?!@$%^&*-]/.test(value)
                            return containsUppercase && containsLowercase && containsNumber && containsSpecial
                        },
                    },
                    confirmPassword: {
                        required,
                        sameAs: sameAs('newPassword'),
                    },
                }
            }
        },
    },
    methods: {
        save() {
            this.$refs.form.submit(this.updatePassword)
        },
        updatePassword() {
            this.$emit('updatePsw', { ...this.form })
        },
    }
}
</script>

<style>

</style>